import React from "react";
import { CommonCard } from "component/common/CommonCard";
import { moreProductData } from "component/common/MockData";
import { Box, IconButton } from "@mui/material";
import MoreProductCardWrapper from "./MoreProductCardWrapper";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { history } from "service/helpers";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const MoreProductCard = ({ isPillLayout = false }) => {
  const language = localStorage.getItem("newTawuniyaLanguageParam");

  const { t, i18n } = useTranslation();

  const items = [
    {
      types: [
        {
          title: "Motor ",
          titleAr: "تأمين المركبات",
          cardUrl: "/individuals/products/motor",
          programs: [
            {
              title: "AlShamel",
              titleAr: "الشامل",
              cardUrl: "/individuals/products/motor",
            },
            {
              title: "Third Party",
              titleAr: "تأمين ضد الغير",
              cardUrl: "/individuals/products/motor",
            },
            {
              title: "Third Party plus",
              titleAr: "تأمين ضد الغير بلس",
              cardUrl: "/individuals/products/motor",
            },
            {
              title: "Mechanical breakdown",
              titleAr: "تأمين الأعطال  الميكانيكية",
              cardUrl: "/individuals/products/motor/mechanical-breakdown",
            },
            {
              title: "Cross Border Own Damage",
              titleAr: "الأضرار الشخصية عبر الحدود",
              cardUrl: "/individuals/products/motor/cross-border-own-damage",
            },
            {
              title: "Tawuniya drive",
              titleAr: "التعاونية درايف",
              cardUrl: "/individuals/loyalty-program/drive",
            },
            {
              title: "Mobility Ecosystem",
              titleAr: "المنظومة المتكاملة للمركبات",
              cardUrl: "/individuals/products/motor/mobility-ecosystem",
            },
          ],
        },
        {
          id: "property",
          title: "General insurance ",
          titleAr: "تأمين العام",
          cardUrl: "/homeinsurance",
          programs: [
            {
              title: "Home insurance",
              titleAr: "تأمين المساكن",
              cardUrl: "/individuals/products/property-casualty/home-insurance",
            },
            {
              title: "Travel insurance",
              titleAr: "تأمين السفر",
              cardUrl:
                "/individuals/products/property-casualty/international-travel",
            },
            {
              title: "Medical malpractice insurance",
              titleAr: "تأمين الأخطاء الطبية",
              cardUrl:
                "/individuals/products/property-casualty/medical-malpractice",
            },
          ],
        },
      ],
    },
    {
      types: [
        {
          title: "Health & Life ",
          titleAr: "تأمين الصحة والحياة",
          cardUrl:
            "/individuals/products/property-casualty/medical-malpractice",
          programs: [
            {
              title: "My family medical insurance",
              titleAr: "تأمين عائلتي الطبي",
              cardUrl: "/individuals/products/health/my-family-insurance",
            },
            {
              title: "Visit visa medical insurance",
              titleAr: "تأمين الزيارة",
              cardUrl: "/individuals/products/health/visit-visa-insurance",
            },
            {
              title: "Hajj insurance program ",
              titleAr: "برنامج تأمين الحج",
              cardUrl: "/individuals/products/health/hajj-insurance",
            },
            {
              title: "Umrah insurance program",
              titleAr: "برنامج تأمين العمرة",
              cardUrl: "/individuals/products/health/umrah-insurance",
            },
            {
              title: "Domestic Worker Health Insurance",
              titleAr: "تأمين الصحي للعمالة المنزلية",
              cardUrl: "/individuals/products/domestic-worker-health-insurance",
            },
            {
              title: "Parent Health Insurance",
              titleAr: "تأمين الوالدين لحالات التنويم و الطوارئ",
              cardUrl: "/individuals/products/Parent-health-insurance",
            },
            {
              title: "Premium Residency Insurance",
              titleAr: "تأمين الإقامة المتميزة",
              externalLink: true,
              cardUrl: "https://www.tawuniya.com/premium-residency/",
            },
          ],
        },
      ],
    },
  ];

  const corporateItems = [
    {
      types: [
        {
          title: "Motor",
          titleAr: "المركبات",
          cardUrl: "/motor/",
          programs: [
            {
              title: "AlShamel",
              titleAr: "التأمين الشامل",
              cardUrl: "/motor/",
            },
            {
              title: "Third Party",
              titleAr: "تأمين ضد الغير",
              cardUrl: "/motor/",
            },
            {
              title: "Third Party plus",
              titleAr: "تأمين ضد الغير بلس",
              cardUrl: "/motor/",
            },
            {
              title: "Mechanical breakdown",
              titleAr: "تأمين الأعطال  الميكانيكية",
              cardUrl: "/motor/mechanical-breakdown",
            },
            {
              title: "Cross Border Own Damage",
              titleAr: "الأضرار الشخصية عبر الحدود",
              cardUrl: "/motor/cross-border-own-damage",
            },
            {
              title: "Tawuniya drive",
              titleAr: "التعاونية درايف",
              cardUrl: "/motor/",
            },
            {
              title: "Mobility Ecosystem",
              titleAr: "المنظومة المتكاملة للمركبات",
              cardUrl: "/motor/mobility-ecosystem",
            },
          ],
        },
        {
          id: "property",
          title: "Property & casualty",
          titleAr: "الممتلكات",
          cardUrl: "/homeinsurance",
          programs: [
            {
              title: "Engineering insurance",
              titleAr: "تأمين المشاريع الهندسية",
              cardUrl: "/corporate/products/enterprise/engineering-insurance",
            },
            {
              title: "General accident insurance",
              titleAr: "تأمين حوادت المسؤليه العامه",
              cardUrl:
                "/corporate/products/enterprise/general-accedent-insurance",
            },
            {
              title: "Property insurance",
              titleAr: "تأمين الممتلكات",
              cardUrl: "/corporate/products/enterprise/property-insurance",
            },
          ],
        },
      ],
    },
    {
      types: [
        {
          title: "Health",
          titleAr: "الطبي",
          cardUrl: "/corporate/products/all-products",
          programs: [
            {
              title: "360° Motor Insurance",
              titleAr: "360 التأمين على المركبات",
              cardUrl: "/individuals/products/motor",
            },
            {
              title: "360° Health Insurance",
              titleAr: "برنامج 360° للتأمين الصحي",
              cardUrl: "/corporate/products/health/360-health-insurance",
            },
            {
              title: "360° General insurance program",
              titleAr: "برنامج 360° أعمال للممتلكات والحوادث",
              cardUrl:
                "/corporate/products/health/360-property_casualty-insurance",
            },
          ],
        },
      ],
    },
  ];

  function serviceNavigator(cardUrl) {
    // window.location.assign(`${cardUrl}`);
  }

  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document
        .getElementsByTagName("body")[0]
        .classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
      document.dir = "rtl";
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
      document.dir = "ltr";
    }
  }, [selectedLanguage]);
  let pathArray = history.location.pathname.split("/");
  let currentPage = pathArray[pathArray.length - 1];
  return (
    <MoreProductCardWrapper>
      <Box className="More-ProductCard-Container">
        {isPillLayout ? (
          <CommonCard width="39em" cardPosition="MoreProduct-Card-Layout">
            <Box className="p-2 pb-4">
              <Box
                className={`${
                  language === "en"
                    ? "corporate-moreProduct-title"
                    : "corporate-moreProduct-title-rtl"
                }`}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pb: 1,
                }}
              >
                <Link
                  to="/individuals/products/all-products"
                  className="global_link global_link_flex global_link_flex_justify"
                  onClick={() => {
                    window.lmSMTObj.track("moreProductsClicked");
                  }}
                >
                  <p className="fs-18 fw-700 mb-0">{t("moreProducts.title")}</p>

                  <IconButton>
                    {language === "en" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                </Link>
              </Box>
              <Box className="row">
                {currentPage === "corporate" ? (
                  <>
                    {corporateItems.map((item) => (
                      <Box key={item.title} className="typesContainer">
                        <Box
                          className="moreProduct-liner"
                          onClick={() => {
                            window.lmSMTObj.track("corporateMIClicked", {
                              productName: item.title,
                            });
                          }}
                        >
                          <Link to={item.cardUrl}>
                            <p className="corporate-enterpriseProductTitle fs-14 fw-800">
                              {language === "en" ? item.title : item.titleAr}
                            </p>
                          </Link>
                          <TypesComponent
                            types={item.types}
                            {...{ language }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <>
                    {items.map((item) => (
                      <Box key={item.title} className="typesContainer">
                        <Box className="moreProduct-liner">
                          <p className="corporate-enterpriseProductTitle fs-14 fw-800">
                            <Link
                              to={item.cardUrl}
                              className="global_link"
                              onClick={() => {
                                console.log("DHT", item);
                                if (item.externalLink) {
                                  window.open(item.cardUrl);
                                }
                                window.lmSMTObj.track("individualsMIClicked", {
                                  productName: item.title,
                                });
                              }}
                            >
                              {language === "en" ? item.title : item.titleAr}
                            </Link>
                          </p>
                          <TypesComponent
                            types={item.types}
                            {...{ language }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </CommonCard>
        ) : (
          <CommonCard width="700px" cardPosition="MoreProduct-Card-Layout">
            <Box className="p-2">
              <p className="fs-18 fw-700 moreProduct-title pb-2">
                {t("moreProducts.title")}
              </p>
              <Box className="d-flex flex-row pb-2">
                {moreProductData.map((item) => (
                  <Box key={item.title} className="pr-5">
                    <p className="fs-16 fw-700">
                      <Link
                        to={item.cardUrl}
                        className="global_link"
                        onClick={() => {
                          window.lmSMTObj.track("individualsMIClicked", {
                            productName: item.title,
                          });
                        }}
                      >
                        {item.title}
                      </Link>
                    </p>
                    {item?.content?.map((items) => {
                      return (
                        <p className="fs-14 fw-400 m-0 pb-2">
                          <Link
                            to={item.cardUrl}
                            className="global_link"
                            onClick={() => {
                              window.lmSMTObj.track("individualsMIClicked", {
                                productName: item.title,
                              });
                            }}
                          >
                            {items}
                          </Link>
                        </p>
                      );
                    })}
                  </Box>
                ))}
              </Box>
            </Box>
          </CommonCard>
        )}
      </Box>
    </MoreProductCardWrapper>
  );
};

const TypesComponent = ({ types, language }) => {
  return (
    <Box className="typesWrapper">
      {types.map((type) => (
        <Box key={type.title}>
          <p
            className={`${
              language === "en"
                ? "corporate-moreproduct-contentTitle"
                : "corporate-moreproduct-contentTitle-rtl"
            } fs-12 fw-800 cursor-pointer`}
          >
            <Link to={type.cardUrl} className="global_link">
              {language === "en" ? type.title : type.titleAr}
            </Link>
          </p>
          {type.programs.map((program) => (
            <p
              key={program.title}
              className={`${
                language === "en"
                  ? "corporate-moreproduct-contentPara"
                  : "corporate-moreproduct-contentPara-rtl"
              } fs-12 fw-400 m-0 pb-2 cursor-pointer`}
            >
              <Link
                to={program.cardUrl}
                className="global_link"
                onClick={() => {
                  if (program.externalLink) window.open(program.cardUrl);
                  window.lmSMTObj.track(program.title + "MIClicked", {
                    productName: type.title,
                    subProductName: program.title,
                  });
                }}
              >
                {language === "en" ? program.title : program.titleAr}
              </Link>
            </p>
          ))}
        </Box>
      ))}
    </Box>
  );
};
