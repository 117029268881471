import React from "react";
import "./style.scss";
import DownArrowOrangeIcon from "assets/images/menuicons/down-arrow-orange.svg";
import RightArrowCircleIcon from "assets/images/menuicons/right-arrow-circle.svg";
import RightArrowIcon from "assets/images/menuicons/right-arrow.svg";
import { useTranslation } from "react-i18next";
import { history } from "service/helpers";
import { ReactComponent as DomesticWorker } from "assets/images/mobile/Domistic Workers icon.svg";
import ParentInsuranceIcon from "assets/images/parents.svg";
import PremiumResidencyIcon from "assets/svg/ProductsIcons.svg";
import {
  ProductsIcon1,
  ProductsIcon2,
  ProductsIcon3,
  ProductsIcon5,
  ProductsIcon6,
  ProductsIcon7,
  ProductsIcon9,
  ProductsIcon11,
  ProductsIcon12,
  ProductsIcon13,
  ProductsIcon14,
  ProductsIcon15,
  ProductsIcon16,
  ProductsIcon17,
  ProductsIcon18,
  ProductsIcon19,
  Alshamal,
  SanadIcon,
  MBI_Icon,
} from "../../../../assets/svg/motor/ValueAddedIcons";
import CBOD_ICON from "../../../../assets/svg/motor/CBOD.svg";
import MOBILITY_ECOSYSTEM_ICON from "../../../../assets/svg/motor/mobility-ecosystem-icon.svg";
import colors from "assets/scss/abstracts/colors.scss";
import { Link } from "react-router-dom";

const Products = ({ setMenuOpen = () => "", currentMenu }) => {
  const { i18n, t } = useTranslation();
  let individualMenuList = [
    {
      title: t("Motor"),
      list: [
        {
          title: t("AlShamel"),
          description: t("Comprehensive_cars_insurance"),
          image: <ProductsIcon1 fill={colors["bright-purple"]} />,
          url: "/individuals/products/motor",
        },
        {
          title: t("productOfferings.sanad"),
          description: t("Motor_vehicle_liability_insurance"),
          image: <ProductsIcon2 fill={colors["bright-purple"]} />,
          url: "/individuals/products/motor",
        },
        {
          title: t("Sanad_Plus"),
          description: t("Motor_vehicle_liability_with_extra_features"),
          image: <ProductsIcon3 fill={colors["bright-purple"]} />,
          url: "/individuals/products/motor",
        },
        {
          title: t("MBI.Title"),
          description: t("MBI.Subtitle"),
          image: <MBI_Icon fill={colors["bright-purple"]} />,
          url: "/individuals/products/motor/mechanical-breakdown",
        },
        {
          title: t("productOfferings.cross_border_own_damage"),
          description: t("productOfferings.cross_border_own_damage_subtitle"),
          image: <img src={CBOD_ICON} alt="icon" width="32px" />,
          url: "/individuals/products/motor/cross-border-own-damage",
        },
        {
          title: t("Tawuniya_Drive"),
          description: t("Tawuniya_Drive"),
          image: <ProductsIcon5 fill={colors["bright-purple"]} />,
          url: "/individuals/products/motor/drive",
        },
        {
          title: t("productOfferings.mobility_ecosystem"),
          description: t("productOfferings.mobility_ecosystem_subtitle"),
          image: <img src={MOBILITY_ECOSYSTEM_ICON} alt="icon" width="32px" />,
          url: "/individuals/products/motor/mobility-ecosystem",
        },
      ],
    },
    {
      title: t("Property_Casualty"),
      list: [
        {
          title: t("Home_Insurance"),
          description: t("Insurance_against_theft_fire_and_additional_risks"),
          image: <ProductsIcon6 fill={colors["bright-purple"]} />,
          url: "/individuals/products/property-casualty/home-insurance",
        },
        {
          title: t("Travel_Insurance"),
          description: t("Travel_Insurance_for_Europe_and_rest_of_the_world"),
          image: <ProductsIcon7 fill={colors["bright-purple"]} />,
          url: "/individuals/products/property-casualty/international-travel",
        },
        {
          title: t("Medical_Malpractice_Insurance"),
          description: t("Medical_Malpractice_Insurance"),
          image: <ProductsIcon9 fill={colors["bright-purple"]} />,
          url: "/individuals/products/property-casualty/medical-malpractice",
        },
        {
          title: t("Domestic_Worker_Insurance_general"),
          description: t("Domestic_Worker_Insurance_general"),
          image: <DomesticWorker />,
          url: "/individuals/products/domestic-worker-insurance",
        },
      ],
    },
    {
      title: t("Medical_Takaful"),
      list: [
        {
          title: t("My_Family"),
          description: t("Families_medical_insurance"),
          image: <ProductsIcon11 fill={colors["bright-purple"]} />,
          url: "/individuals/products/health/my-family-insurance",
        },
        {
          title: t("Visit_Visa_Medical_Insurance"),
          description: t("Medical_Insurance_for_Saudi_Arabia_Visitors"),
          image: <ProductsIcon12 fill={colors["bright-purple"]} />,
          url: "/individuals/products/health/visit-visa-insurance",
        },
        {
          title: t("Hajj_Insurance_Program"),
          description: t("For_Foreign_Pilgrims"),
          image: <ProductsIcon13 fill={colors["bright-purple"]} />,
          url: "/individuals/products/health/hajj-insurance",
        },
        {
          title: t("Umrah_Insurance_Program"),
          description: t("For_Foreign_Pilgrims_umrah"),
          image: <ProductsIcon14 fill={colors["bright-purple"]} />,
          url: "/individuals/products/health/umrah-insurance",
        },
        {
          title: t("Domestic_Worker_Insurance_for_Health_Life"),
          description: t("Domestic_Worker_Insurance_for_Health_Life"),
          image: <DomesticWorker />,
          url: "/individuals/products/domestic-worker-health-insurance",
        },
        {
          title: t("Parents_Insurance_for_Health_Life"),
          description: t("Parents_Medical_Insurance_for_Health_Life"),
          image: <img src={ParentInsuranceIcon} alt="parent-insurance-icon" />,
          url: "/individuals/products/Parent-health-insurance",
        },
        {
          title: t("Premium_Residency_for_Health_Life"),
          description: t("Premium_Residency_for_Health_Life_description"),
          image: (
            <img
              src={PremiumResidencyIcon}
              alt="premium-residency-insurance-icon"
            />
          ),
          externalLink: true,
          url: "https://www.tawuniya.com/premium-residency/",
        },
      ],
    },
    {
      title: t("Life"),
      list: [
        {
          title: t("lifeInsurance.lifeInsurance"),
          description: t("lifeInsurance.product1-desc"),
          image: <ProductsIcon11 fill={colors["bright-purple"]} />,
          url: "/individuals/products/life-insurance",
        },
        {
          title: t("lifeInsurance.lifeInsurancePlus"),
          description: t("lifeInsurance.product2-desc"),
          image: <ProductsIcon11 fill={colors["bright-purple"]} />,
          url: "/individuals/products/life-insurance",
        },
        {
          title: t("lifeInsurance.RetirementInsurance"),
          description: t("lifeInsurance.product3-desc"),
          image: <ProductsIcon11 fill={colors["bright-purple"]} />,
          url: "/individuals/products/life-insurance",
        },
        {
          title: t("lifeInsurance.EducationProgram"),
          description: t("lifeInsurance.product4-desc"),
          image: <ProductsIcon11 fill={colors["bright-purple"]} />,
          url: "/individuals/products/life-insurance",
        },
      ],
    },
  ];

  let lessEmployeesMenuList = [
    {
      title: t("360_Health_Insurance"),
      description: t("Health_Insurance_for_Small_Medium_Business"),
      url: "/corporate/products/health/360-health-insurance",
    },
    {
      title: t("360_Property_Casuality Program"),
      description: t("Health_Insurance_for_Small_Medium_Business3"),
      url: "/corporate/products/health/360-property_casualty-insurance",
    },
  ];

  let moreEmployeesMenuList = [
    {
      title: t("Motor-1"),
      list: [
        {
          title: t("AlShamel"),
          description: t("Comprehensive_cars_insurance"),
          image: <Alshamal />,
          url: "/individuals/products/motor",
        },
        {
          title: t("allProductsIndividuals.Sanad"),
          description: t("Motor_vehicle_liability_insurance"),
          image: <SanadIcon fill={colors["bright-purple"]} />,
          url: "/individuals/products/motor",
        },
      ],
      cardPd: "pr-2",
    },
    {
      title: t("Medical"),
      list: [
        {
          title: t("Balsam"),
          description: t("Medical-in"),
          image: <ProductsIcon11 fill={colors["bright-purple"]} />,
          url: "/corporate/products/health/balsam",
        },
        {
          title: t("Takaful"),
          description: t("Program-2"),
          image: <ProductsIcon9 fill={colors["bright-purple"]} />,
          url: "/corporate/products/health/takaful",
        },
      ],
      cardPd: "pl-2",
    },
  ];

  let moreEmployeesMenuLastList = [
    {
      title: t("Property-&-Casualty"),
      list: [
        {
          title: t("Engineering"),
          description: t("Health-1"),
          image: <ProductsIcon17 fill={colors["bright-purple"]} />,
          url: "/corporate/products/enterprise/engineering-insurance",
        },
        {
          title: t("Services-Insurance"),
          description: t("Health-3"),
          image: <ProductsIcon18 fill={colors["bright-purple"]} />,
          url: "/corporate/products/enterprise/general-accedent-insurance",
        },
        {
          title: t("Indsut"),
          description: t("Health-2"),
          image: <ProductsIcon19 fill={colors["bright-purple"]} />,
          url: "/corporate/products/enterprise/property-insurance",
        },
      ],
    },
  ];

  const handleMenuClick = (item, index) => {
    setMenuOpen(null);
    if (index === 0) {
      window.location.assign(item?.url);
    } else {
      window.location.assign(item?.url);
    }
  };

  const TrackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  };
  const TrackEventProduct = (category, productTitle, subProduct) => {
    window.lmSMTObj.track(subProduct + "TabClicked", {
      category: category,
      product: "Product",
      subProduct: productTitle,
    });
  };
  return (
    <div className="products-list-menu-root">
      {currentMenu === "Individuals" ? (
        <>
          <div className="products-list-menu">
            {individualMenuList?.map((menu, index) => {
              return (
                <div className="menu-list-section" key={index.toString()}>
                  <div className="menu-list-title">
                    {menu?.title}{" "}
                    <img src={DownArrowOrangeIcon} alt="DownArrowOrangeIcon" />
                  </div>
                  {menu?.list?.map((item, childIndex) => {
                    return (
                      // <NavLink
                      // 	to={{ pathname: item?.url }}
                      // 	className="menu-details"
                      // 	key={childIndex.toString()}
                      // 	onClick={() => setMenuOpen(null)}
                      // 	target="_blank"
                      // 	exact
                      // >
                      <div
                        className="menu-details cursor-pointer"
                        // onClick={() => handleMenuClick(item, index)}
                        onClick={() =>
                          TrackEventProduct(currentMenu, menu.title, item.title)
                        }
                      >
                        <Link
                          to={item?.url}
                          onClick={() => {
                            if (item.externalLink) window.open(item.url);
                            setMenuOpen(null);
                          }}
                          className="global_link global_link_flex"
                        >
                          <div className="image">{item?.image}</div>
                          <div className="text-content">
                            <div className="title">{item?.title}</div>
                            <div className="description">
                              {item?.description}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="all-products">
            <Link
              onClick={() => {
                setMenuOpen(null);
                TrackEvent(t("View_All_Products ") + "TabClicked");
              }}
              to={"/individuals/products/all-products"}
              className="global_link"
            >
              {t("View_All_Products ")}
              <img src={RightArrowIcon} alt="RightArrowIcon" />
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="products-list-menu-after-login">
            <div className="section-left">
              <div className="section-title">
                <div className="margin-inline">
                  <ProductsIcon15 />
                </div>
                <div>
                  {t("Business")}
                  <br />
                  {t("MORE_than")} <span className="red">{t("250")}</span>{" "}
                  {t("employessm")}
                </div>
              </div>
              <div className="section-content">
                <div className="more-employees-list row">
                  {moreEmployeesMenuList?.map(({ title, list }, index) => {
                    return (
                      <div className={`${list.cardPd} col-6`}>
                        <div
                          className="more-employees-box"
                          key={index.toString()}
                        >
                          <div className="more-employees-title">{title}</div>
                          {list.map((item, childIndex) => {
                            return (
                              <div
                                className="more-employees"
                                key={childIndex.toString()}
                                onClick={() => {
                                  setMenuOpen(null);
                                  TrackEventProduct(
                                    currentMenu,
                                    title,
                                    item.title
                                  );
                                }}
                              >
                                <Link
                                  to={item.url}
                                  onClick={(e)=>{
                                    e.preventDefault();
                                    history.push(item.url);
                                  }}
                                  className="global_link global_link_flex"
                                >
                                  <div className="image">{item?.image}</div>
                                  <div className="text-content">
                                    <div className="title">{item?.title}</div>
                                    <div className="description">
                                      {item?.description}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="more-employees-list">
                  {moreEmployeesMenuLastList?.map(({ title, list }, index) => {
                    return (
                      <div
                        className="more-employees-box"
                        key={index.toString()}
                      >
                        <div className="more-employees-title">{title}</div>
                        {list.map((item, childIndex) => {
                          return (
                            <div
                              target="_blank"
                              to={{ pathname: item?.url }}
                              className="more-employees"
                              key={childIndex.toString()}
                              onClick={() => {
                                setMenuOpen(null);
                                TrackEventProduct(
                                  currentMenu,
                                  title,
                                  item.title
                                );
                              }}
                            >
                              <Link
                                to={item.url}
                                className="global_link global_link_flex"
                              >
                                <div className="image">{item?.image}</div>
                                <div className="text-content">
                                  <div className="title">{item?.title}</div>
                                  <div className="description">
                                    {item?.description}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div
                  className="all-products"
                  onClick={() => {
                    setMenuOpen(null);
                    TrackEvent(t("View_All_Products ") + "TabClicked");
                  }}
                >
                  <Link
                    to="/corporate/products/all-products"
                    className="global_link global_link_flex"
                  >
                    <div>
                      <div>
                        {t("View_All_Products ")}
                        <img src={RightArrowIcon} alt="RightArrowIcon" />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="section-right">
              <div className="section-title">
                <div className="margin-inline">
                  <ProductsIcon16 />
                </div>
                <div>
                  {t("Business")}
                  <br />
                  {t("Less")} <span className="red">{t("250")}</span>{" "}
                  {t("employessm")}
                </div>
              </div>
              <div className="section-content">
                <div className="less-employees-list">
                  {lessEmployeesMenuList?.map((item, index) => {
                    return (
                      <div
                        className="less-employees cursor-pointer"
                        key={index.toString()}
                        onClick={() => {
                          setMenuOpen(null);
                          TrackEventProduct(currentMenu, "NA", item.title);
                        }}
                      >
                        <Link
                          to={item.url}
                          className="global_link global_link_flex"
                        >
                          <span className="title">{item?.title}</span>
                          <span className="description">
                            {item?.description}
                          </span>
                          <span className="image">
                            <img
                              src={RightArrowCircleIcon}
                              alt="RightArrowCircleIcon"
                            />
                          </span>
                        </Link>
                      </div>
                    );
                  })}
                </div>
                {/*<div className="all-products" onClick={() => setMenuOpen(null)}>
         <a
          href="https://www.tawuniya.com.sa/corporate/products/sme"
          target="_blank"
          className="global_link global_link_flex"
         >
          <div>
           {t("View_All_Products ")}
           <img src={RightArrowIcon} alt="RightArrowIcon" />
          </div>
         </a>
        </div>*/}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Products;
