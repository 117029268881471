import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect, HashRouter, useHistory, useParams, Switch } from "react-router-dom";
import CodeSplitter from "service/helpers/CodeSplitter";
import { NotificationContainer } from "react-notifications";
import Routers from "./routes";
import * as Layout from "layout";
import { useDispatch, useSelector } from "react-redux";
import Hotjar from "@hotjar/browser";
import { useTranslation } from "react-i18next";
import { updateLanguage } from "action/LanguageAct";
import { Loader } from "../component/common/Loader";
import { isEmpty } from "lodash";


const RoutesComponent = () => {

    const valueLang = useSelector((state) => state?.languageReducer?.language)
    const selectedLanguage = isEmpty(localStorage.getItem("newTawuniyaLanguageParam")) ? valueLang : localStorage.getItem("newTawuniyaLanguageParam");
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const lang = window.location.pathname.slice(1, 3);
    let entered = false
    const isChosed = localStorage.getItem("isChosed") || false

    useEffect(() => {
        if (window.location.href.includes('/.well-known/apple-developer-merchantid-domain-association.txt')) return;
        if(!entered) {
            entered = true
            if (lang === 'en' || lang === 'ar') {
                if (lang !== selectedLanguage) {
                    dispatch(updateLanguage(isChosed ? selectedLanguage : lang));
                    isChosed && window.location.replace(`${window.location.pathname.replace(lang, selectedLanguage)}`);
                    localStorage.setItem("isChosed", false)
                }
            } else {
                window.location.replace(`${window.location.origin}/${selectedLanguage}${window.location.pathname}`);
            }
        }

    }, [selectedLanguage]);


    const hotjarInitialization = () => {
        Hotjar.init("2581545", "6");
    };

    useEffect(() => {
        hotjarInitialization();
        const lang = window.location.hash
            .split("?")[1]
            ?.split("&")
            .find((el) => el.indexOf("lang") !== -1)
            ?.slice(-2);

        if (i18n.resolvedLanguage !== lang) {
            if (lang === "en" || lang === "ar") {
                i18n.changeLanguage(lang);
                dispatch(updateLanguage(lang === "en" ? "en" : "ar"));
            }
        }
    }, []);

    useEffect(() => {
        const body = document.getElementById("root");
        body.setAttribute("dir", `${selectedLanguage === "ar" ? "rtl" : "ltr"}`);
        body.classList.add(`${selectedLanguage === "ar" ? "rtl" : "ltr"}`);
        body.classList.remove(`${selectedLanguage !== "ar" ? "rtl" : "ltr"}`);
    }, [selectedLanguage]);

    const history = useHistory();

    const createHref = (location) => {
        const pathname = `/${selectedLanguage}/${location.pathname}`;
        const search = location.search || '';
        const hash = location.hash || '';
        return history.createHref({ pathname, search, hash });
    };

    const redirectURL = () => {
        const origin = "https://app.tawuniya.com";
        if (origin === window.location.origin) {
            return "/login";
        }
        return "/";
    }

    return (
        <BrowserRouter {...{ basename: `/${selectedLanguage}`, history: { ...history, createHref } }}>
            <Suspense fallback={<Loader />}>
                <Switch>
                    {Routers.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => {
                                    const Layout = require(`layout/${route.layout}`).default;

                                    CodeSplitter.addComponent(route.componentPath, route.name);

                                    let PageComponent = CodeSplitter.getComponent(route.name);

                                    return (
                                        <Layout>
                                            <PageComponent />
                                        </Layout>
                                    );
                                }}
                            />
                        );
                    })}
                    <Route path="*">
                        <Redirect to={redirectURL()} />
                    </Route>
                </Switch>
                <NotificationContainer />
            </Suspense>
        </BrowserRouter>
    );
};

export default RoutesComponent;
