import React, { useEffect, useState, useMemo } from "react";
import { Navbar } from "component/common/Navbar";
import { NormalOffCanvas } from "component/common/NormalOffCanvas";
import { HomeBanner } from "component/HomePage/LandingComponent";
import {
  EmergencyCard,
  LanguageCard,
  SupportCard,
  GetStartedCard,
} from "component/HomePage/LandingComponent/NavbarCards";
import { CommonFooter } from "component/common/CommonFooter";

import home from "assets/svg/menu/Unionhome.png";
import file from "assets/svg/menu/Fileclaim.png";
import bag from "assets/svg/menu/Bag.png";
import user from "assets/svg/menu/Userprofile.png";
import greenball from "assets/svg/menu/rename.png";
import "./MyLayoutStyle.scss";
import LoginPageMobile from "component/Auth/LoginPageMobile";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate, updateLanguage } from "action/LanguageAct";
import { useTranslation } from "react-i18next";
import AnimatedBottomPopup from "component/common/MobileReuseable/AnimatedBottomPopupHome";
import phoneIcon from "assets/svg/phoneCircleIcon.svg";
import mailIcon from "assets/svg/menu/mailmessage.svg";
import "./app.css";
import chatIcon from "assets/svg/menu/Vectorchat.svg";
import isEmpty from "lodash/isEmpty";
import PlayStore from "assets/svg/menu/Storeplaystore.svg";
import Close from "assets/svg/menu/Closeclose.svg";
import preloader from "assets/loader.gif";
import { AppBanner } from "../component/MotorPage/MotorInsuranceComponent";
import ExtraHeaderDesktop from "component/common/ExtraHeader/ExtraHeaderDesktop";
import { history } from "service/helpers";
import MockupLanding from "assets/images/Landing/MockupLanding.png";
import { Helmet } from "react-helmet";

const MyLayout = (props) => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  useEffect(() => {
    // Clear session storage
    sessionStorage.clear();

    // Clear local storage
    localStorage.clear();
  }, []);

  const [emergencyCard, setEmergencyCard] = useState(false);
  const [getStartedCard, setGetStartedCard] = useState(false);
  const [isOpenLoginModel, setIsOpenLoginModel] = useState(false);
  const valueLang = useSelector((state) => state?.languageReducer?.language)
  const selectedLanguage = isEmpty(localStorage.getItem("newTawuniyaLanguageParam")) ? valueLang : localStorage.getItem("newTawuniyaLanguageParam");

  useEffect(() => {

    dispatch(updateLanguage(selectedLanguage));
  }, [selectedLanguage])
  const loginResponse = useSelector((state) => state?.loginDetailsReducer?.loginResponse);
  const loader = useSelector((state) => state.dashboardInformationReducer.loader);
  const userInfo = loginResponse?.user_info;
  const [languageCard, setLanguageCard] = useState(false);
  const [open, setOpen] = useState(false);
  const [supportCard, setSupportCard] = useState(false);
  const isOtpVerified = useSelector((state) => state.loginDetailsReducer?.isOtpVerified);

  const toggleLanguageCard = () => {
    setLanguageCard(!languageCard);
  };

  const toggleSupportCard = () => {
    setSupportCard(!supportCard);
  };

  const toggleEmergencyCard = () => {
    setEmergencyCard(!emergencyCard);
  };

  const toggleGetStartedCard = () => {
    setGetStartedCard(!getStartedCard);
  };

  const navHomeContent = [
    {
      id: 0,
      navText: t("navbar.individuals"),
      link: "/individuals",
    },
    {
      id: 1,
      navText: t("navbar.corporate"),
      link: "/corporate",
    },
    {
      id: 4,
      navText: t("navbar.Partners"),
      link: "https://jisr.waseel.com/signIn",
    },
    {
      id: 2,
      navText: t("navbar.investor"),
      link: "/investor",
    },
    {
      id: 3,
      navText: t("navbar.claims"),
    },

  ];

  const historyLinks = ["/", "/motorpage"];

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  const closeToggler = () => {
    setToggle(false);
  };

  const handleToggler = () => {
    setToggle(true);
  };

  const handleProfileClick = () => {
    if (!isOtpVerified) {
      return setIsOpenLoginModel(true);
    } else {
      history.push("/dashboard/myprofile");
    }
  };

  const handleSupportClick = () => {
    history.push("/individuals/customer-service/support");
  };

  const handleHomeClick = () => {
    if (isOtpVerified) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  };

  const handleClaimClick = () => {
    if (isEmpty(userInfo)) {
      history.push("/individuals/products/motor/claims");
    } else {
      history.push("/dashboard/all-claims");
    }
  };

  const pushPlaystore = () => {
    window.location.assign(
      "https://apps.apple.com/us/app/tawuniya-%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9/id573176914"
    );
  };

  const [swap, setSwap] = useState(null);

  const changeImg = [chatIcon, mailIcon, phoneIcon];

  const [transition, setTransition] = useState(0);

  useEffect(() => {
    setSwap(changeImg[transition]);
  }, [transition]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (transition + 1 === changeImg.length) {
        setTransition(0);
      } else {
        setTransition((item) => item + 1);
      }
    }, 3000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 300000);
    return () => clearTimeout(timer);
  });

  const translateHandler = () => {
    selectedLanguage === "ar" ? dispatch(arabTranslate(false)) : dispatch(arabTranslate(true));
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: selectedLanguage === "ar" ? "ar" : "en" }} />
      {window.innerWidth > 950 ? (
        <>
          {toggle ? (
            <div className="row">
              <div className="col-12 tawuniyaCanvasContainer vh-100">
                <NormalOffCanvas closeToggler={closeToggler} />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {loader && (
                <div className="loader-tawuniya">
                  <img src={preloader} alt="preloader" />
                </div>
              )}
              <div
                className="vh-100 1"
                onClick={
                  supportCard
                    ? () => supportCard && setSupportCard(false)
                    : languageCard
                      ? () => languageCard && setLanguageCard(false)
                      : getStartedCard
                        ? () => getStartedCard && setGetStartedCard(false)
                        : emergencyCard
                          ? () => emergencyCard && setEmergencyCard(false)
                          : null
                }
              >
                {historyLinks.includes(history.location.pathname) && <HomeBanner />}

                {window.location.hash === "/individuals/products/motor" && <AppBanner />}

                <div className="row">
                  <div className="col-12">
                    <Navbar
                      handleToggler={handleToggler}
                      toggleLanguageCard={toggleLanguageCard}
                      toggleSupportCard={toggleSupportCard}
                      toggleEmergencyCard={toggleEmergencyCard}
                      toggleGetStartedCard={toggleGetStartedCard}
                      navContent={navHomeContent}
                    />

                    <ExtraHeaderDesktop />
                  </div>
                  <div className="col-12 paddingContainer landingContainer">
                    {languageCard ? <LanguageCard translateHandler={translateHandler} /> : null}
                    {supportCard ? <SupportCard /> : null}
                    {emergencyCard ? <EmergencyCard /> : null}
                    {getStartedCard ? <GetStartedCard /> : null}

                    <div className="paddingContainer">{props.children}</div>
                  </div>
                </div>
                <CommonFooter />
              </div>
            </React.Fragment>
          )}
        </>
      ) : (
        <>
          {props.children}
          {/*  {open && (
      <AnimatedBottomPopup open={open} setOpen={setOpen} minHeight={"320px"}>
       <div className="contact_us_thanks_model">
        <div className="contact_us_close" onClick={() => setOpen(false)}>
         <img src={Close} alt="ConfirmationWoman" className="Close" />
        </div>

        <div className="clas_img d-flex align-items-center justify-content-center">
         <img
          src={MockupLanding}
          alt="MockupLanding"
          className="position-fixed bottom-400 img-change"
         />
        </div>

        <div className="contact_us_thanks d-flex align-items-center justify-content-center font-weight-bold mt-4  text-bold">
        <p className="tawuniya w-90">
         {t("myprofile.get")}
        </p>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-4">
         <img
          src={PlayStore}
          alt="PlayStore"
          className="playstore"
          onClick={() => pushPlaystore()}
         />
        </div>
       </div>
      </AnimatedBottomPopup>
     )}*/}
          {/* <div id="widgetMobile">
      <div className="mobile_sticky_bottom_menu">
       <div className="card-body p-20 bottom-icons  third-footer bg2">
        <div className="footer-icons">
         <div className="footer_body">
          <div className="text-center" onClick={() => handleHomeClick()}>
           <img src={home} alt="DawaiHome" />
           <p className="active_menu">
            {isOtpVerified ? t("myprofile.Dashboard") : t("myprofile.Home")}
           </p>
          </div>
          <div className="text-center third-click" onClick={() => handleClaimClick()}>
           <img src={file} alt="Claims" />
           <p className="">{t("myprofile.claims")}</p>
          </div>
          {isOtpVerified ? (
           <div
            className={` text-center ${
             isRTL ? "greenball-placing-dash-ar" : "greenball-dash-placing"
            }`}
            onClick={() => handleSupportClick()}
           >
            <div className="pr-lg-2">
             <img
              src={swap}
              className="img-fluid notifyBtnIcon"
              onClick={toggleSupportCard}
              alt="notifyicon"
             />
            </div>
            <img className="green-img" src={greenball} alt="Green" />
           </div>
          ) : (
           <div
            className={` text-center ${isRTL ? "greenball-placing-ar" : "greenball-placing"}`}
            onClick={() => handleSupportClick()}
           >
            <div className="pr-lg-2">
             <img
              src={swap}
              className="img-fluid notifyBtnIcon"
              onClick={toggleSupportCard}
              alt="notifyicon"
             />
            </div>
            <img className="green-img" src={greenball} alt="Green" />
           </div>
          )}

          <div
           className="text-center"
           onClick={() => history.push("/individuals/all-products-mobile")}
          >
           <img src={bag} alt="Products" />
           <p className="">{t("myprofile.products")}</p>
          </div>
          <div className="text-center" onClick={() => handleProfileClick()}>
           <img src={user} alt="Profile" />
           <p className=""> {!isOtpVerified ? t("myprofile.Login") : t("myprofile.Profile")}</p>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>*/}
          <LoginPageMobile
            isOpenLoginModel={isOpenLoginModel}
            setIsOpenLoginModel={setIsOpenLoginModel}
          />
        </>
      )}
    </>
  );
};

export default MyLayout;
