export default [
  {
    component: "PageNotFound404",
    path: "/page-not-found",
    componentPath: "pages/PageNotFound404",
    name: "PageNotFound404",
    auth: false,
    exact: true,
    layout: "PageNotFoundLayout/PageNotFoundLayout",
  },
  {
    component: "LandingPage",
    path: "/",
    componentPath: "pages/HomePage/LandingPage.js",
    name: "LandingPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "Individuals",
    path: "/:lang?/individuals",
    componentPath: "pages/HomePage/Individuals.js",
    name: "Individual",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AllServiceMobilePage",
    path: "/:lang?/quick-support",
    componentPath: "pages/AllServiceMobilePage/AllServiceMobilePage.js",
    name: "AllServiceMobilePage",
    auth: false,
    exact: true,
    layout: "MyLayout",

  },
  {
    component: "MotorInsurancePage",
    path: "/:lang?/individuals/products/motor",
    componentPath: "pages/MotorPage/MotorInsurancePage.js",
    name: "MotorInsurancePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MotorInsurancePage",
    path: "/:lang?/individuals/products/motor/kashif",
    componentPath: "pages/MotorPageKashif/MotorInsurancePage.js",
    name: "MotorInsurancePageKashif",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MBIPage",
    path: "/:lang?/individuals/products/motor/mechanical-breakdown",
    componentPath: "pages/MotorMBI/MBIPage.js",
    name: "MBIPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "CBODPage",
    path: "/:lang?/individuals/products/motor/cross-border-own-damage",
    componentPath: "pages/MotorCBOD/CBODPage.js",
    name: "CBODPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MobilityEcosystem",
    path: "/:lang?/individuals/products/motor/mobility-ecosystem",
    componentPath: "pages/MotorMobilityEcosystem/MotorMobilityEcosystemPage.js",
    name: "MotorMobilityEcosystemPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "DrivePage",
    path: "/:lang?/individuals/products/motor/drive",
    componentPath: "pages/LoyaltyProgram/DrivePage/DrivePage.js",
    name: "DrivePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HomeInsurancePage",
    path: "/:lang?/individuals/products/property-casualty/home-insurance",
    componentPath:
      "pages/Products/Individuals/PropertyAndCasuality/HomeInsurancePage/HomeInsurancePage.js",
    name: "HomeInsurancePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "InternationalTravelPage",
    path: "/:lang?/individuals/products/property-casualty/international-travel",
    componentPath:
      "pages/Products/Individuals/PropertyAndCasuality/InternationalTravelPage/InternationalTravelPage.js",
    name: "InternationalTravelPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MedicalMalpracticePage",
    path: "/:lang?/individuals/products/property-casualty/medical-malpractice",
    componentPath:
      "pages/Products/Individuals/PropertyAndCasuality/MedicalMalpracticePage/MedicalMalpracticePage.js",
    name: "MedicalMalpracticePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HealthInsuranceMyFamilyPage",
    path: "/:lang?/individuals/products/health/my-family-insurance",
    componentPath:
      "pages/Products/Individuals/PropertyAndCasuality/HealthInsuranceMyFamilyPage/HealthInsuranceMyFamilyPage.js",
    name: "HealthInsuranceMyFamilyPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HealthInsuranceVisitVisaPage",
    path: "/:lang?/individuals/products/health/visit-visa-insurance",
    componentPath:
      "pages/Products/Individuals/PropertyAndCasuality/HealthInsuranceVisitVisaPage/HealthInsuranceVisitVisaPage.js",
    name: "HealthInsuranceVisitVisaPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HajjInsuranceProgram",
    path: "/:lang?/individuals/products/health/hajj-insurance",
    componentPath:
      "pages/Products/Individuals/PropertyAndCasuality/HajjUmrhaPages/HajjInsuranceProgram",
    name: "HajjInsuranceProgram",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "InsuranceMobile",
    path: "/:lang?/individuals/products/health/hajj-insurance-mobile",
    componentPath: "pages/InsuranceMobile/InsuranceMobile.js",
    name: "InsuranceMobile",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "UnrahInsuranceProgram",
    path: "/:lang?/individuals/products/health/umrah-insurance",
    componentPath:
      "pages/Products/Individuals/PropertyAndCasuality/HajjUmrhaPages/UmrahInsuranceProgram",
    name: "UnrahInsuranceProgram",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "InsuranceMobile",
    path: "/:lang?/individuals/products/health/umra-insurance-mobile",
    componentPath: "pages/InsuranceMobile/InsuranceMobile.js",
    name: "InsuranceMobile",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AllProductsIndividualsPage",
    path: "/:lang?/individuals/products/all-products",
    componentPath:
      "pages/Products/Individuals/AllProducts/AllProductsIndividualsPage/AllProductsIndividualsPage.js",
    name: "AllProductsIndividualsPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AllProductsEnterprisePage",
    path: "/:lang?/corporate/products/all-products",
    componentPath:
      "pages/Products/Individuals/AllProducts/AllProductsEnterprisePage/AllProductsEnterprisePage.js",
    name: "AllProductsEnterprisePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "VitalityPage",
    path: "/:lang?/individuals/loyalty-program/vitality",
    componentPath: "pages/Products/Individuals/LoyaltyPrograms/VitalityPage/VitalityPage.js",
    name: "VitalityPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "TawuniyaDrivePage",
    path: "/:lang?/individuals/loyalty-program/drive",
    componentPath: "pages/LoyaltyProgramsPage/TawuniyaDrivePage",
    name: "TawuniyaDrivePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "IthraPage",
    path: "/:lang?/individuals/loyalty-program/ithra",
    componentPath: "pages/LoyaltyProgram/IthraPage/IthraPage.js",
    name: "IthraPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  // {
  //   component: "IthraPrimePage",
  //   path: "/:lang?/individuals/loyalty-program/ithra-prime",
  //   componentPath: "pages/LoyaltyProgram/IthraPrimePage/IthraPrimePage.js",
  //   name: "IthraPrimePage",
  //   auth: false,
  //   exact: true,
  //   layout: "MyLayout",
  // },
  {
    component: "IthraPrimePage",
    path: "/:lang?/individuals/loyalty-program/taj",
    componentPath: "pages/LoyaltyProgram/TajPage/TajPage.js",
    name: "TajPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "CustomerServiceBanner",
    path: "/:lang?/individuals/customer-service",
    componentPath: "pages/CustomerService/CustomerServiceLanding/CustomerServiceLandingPage.js",
    name: "CustomerServiceBanner",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ReportPage",
    path: "/:lang?/individuals/customer-service/report-fraud",
    componentPath: "pages/ReportPage/ReportPage.js",
    name: "ReportPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "Violations Page",
    path: "/:lang?/individuals/customer-service/violations",
    componentPath: "pages/CustomerService/ViolationsPage/ViolationsPage.js",
    name: "ViolationsPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },

  {
    component: "NetworkCoverage",
    path: "/:lang?/individuals/customer-service/network-coverage",
    componentPath: "pages/NetworkCoverage/NetworkCoverage.js",
    name: "NetworkCoverage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "OpenComplaint",
    path: "/:lang?/individuals/customer-service/support",
    componentPath: "pages/CustomerService/OpenComplaint/OpenComplaint.js",
    name: "OpenComplaint",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "SupportCenterRequest",
    path: "/:lang?/individuals/customer-service/support-center",
    componentPath: "pages/CustomerService/SupportCenter/SupportCenter.js",
    name: "SupportCenter",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "SupportRequest",
    path: "/:lang?/individuals/customer-service/medical-fraud",
    componentPath: "pages/CustomerService/SupportRequest/SupportRequest.js",
    name: "SupportRequest",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MotorFraud",
    path: "/:lang?/individuals/customer-service/motor-fraud",
    componentPath: "pages/CustomerService/MotorFraud/MotorFraud.js",
    name: "MotorFraud",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "TravelFraud",
    path: "/:lang?/individuals/customer-service/travel-fraud",
    componentPath: "pages/CustomerService/TravelFraud/TravelFraud.js",
    name: "TravelFraud",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ChronicDiseaseManagement",
    path: "/:lang?/individuals/customer-service/chronic-disease-management",
    componentPath: "pages/CustomerService/ChronicDiseaseManagement/ChronicDiseaseManagement.js",
    name: "ChronicDiseaseManagement",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HomeChildVaccination",
    path: "/:lang?/individuals/customer-service/home-child-vaccination",
    componentPath: "pages/CustomerService/HomeChildVaccination/HomeChildVaccination.js",
    name: "HomeChildVaccination",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "RoadAssistance",
    path: "/:lang?/individuals/customer-service/road-assistance",
    componentPath: "pages/CustomerService/RoadAssistance/RoadAssistance.js",
    name: "RoadAssistance",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HomePeriodicalInspection",
    path: "/:lang?/individuals/customer-service/periodic-inspection",
    componentPath:
      "pages/Dashboard/HomePeriodicInspectionPage/HomePeriodicalInspection/HomePeriodicalInspection.js",
    name: "HomePeriodicalInspection",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "PregnancyProgram",
    path: "/:lang?/individuals/customer-service/pregnancy-follow-up-program",
    componentPath: "pages/CustomerService/PregnancyProgram/PregnancyProgram.js",
    name: "PregnancyProgram",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AssistAmericaPage",
    path: "/:lang?/individuals/customer-service/assist-america",
    componentPath: "pages/CustomerService/AssistAmerica/AssistAmerica.js",
    name: "AssistAmericaPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "Surplus",
    path: "/:lang?/individuals/customer-service/surplus",
    componentPath: "pages/CustomerService/Surplus/SurplusPage.js",
    name: "Surplus",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MedicalFraud",
    path: "/:lang?/individuals/customer-service/service-questions",
    componentPath: "pages/CustomerService/MedicalFraud/MedicalFraudPage.js",
    name: "MedicalFraud",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ServicesCategory",
    path: "/:lang?/individuals/customer-service/service-category",
    componentPath: "pages/CustomerService/ServicesCategory/ServicesCategory.js",
    name: "ServicesCategory",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "SupportRequestConfirmation",
    path: "/:lang?/individuals/customer-service/support-request-confirmation",
    componentPath: "pages/CustomerService/SupportRequestConfirmation/SupportRequestConfirmation.js",
    name: "SupportRequestConfirmation",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "RoadAssistantLandingPage",
    path: "/:lang?/individuals/customer-service/road-assistances",
    componentPath:
      "pages/Dashboard/RoadAssistancePage/RoadAssistantLandingPage/RoadAssistantLandingPage.js",
    name: "RoadAssistantLandingPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "BalsamContainer",
    path: "/:lang?/corporate/products/health/balsam",
    componentPath: "pages/Products/SME/Medical/Balsam/index.js",
    name: "BalsamInsurancePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HealthBalsamPlansPage",
    path: "/:lang?/corporate/products/health/balsamplans",
    componentPath: "pages/Products/SME/Health/HealthBalsamPlansPage/HealthBalsamPlansPage.js",
    name: "HealthBalsamPlansPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HealthTakafulPage",
    path: "/:lang?/corporate/products/health/takaful",
    componentPath: "pages/Products/Individuals/Health/HealthTakafulPage/HealthTakafulPage.js",
    name: "HealthTakafulPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HealthSMEPage",
    path: "/:lang?/corporate/products/health/360-health-insurance",
    componentPath: "pages/Products/SME/Health/HealthSMEPage/HealthSMEPage.js",
    name: "HealthSMEPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
   {
    component: "HealthSMEPlansPage",
    path: "/:lang?/corporate/products/health/360-health-insurance/plans",
    componentPath: "pages/Products/SME/Health/HealthSMEPlansPage/HealthSMEPlansPage.js",
    name: "HealthSMEPlansPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ThreeSixtyPropertyInsurancePage",
    path: "/:lang?/corporate/products/health/360-property_casualty-insurance",
    componentPath:
      "pages/Products/SME/PropertyAndCasuality/ThreeSixtyPropertyInsurancePage/ThreeSixtyPropertyInsurancePage.js",
    name: "ThreeSixtyPropertyInsurancePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ServicesInsurancePage",
    path: "/:lang?/corporate/products/enterprise/:url",
    componentPath:
      "pages/Products/Enterprise/PropertyAndCasuality/ServicesInsurancePage/ServicesInsurancePage.js",
    name: "ServicesInsurancePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AllProductsEnterprisePage",
    path: "/:lang?/corporate/products/enterprise/all-products",
    componentPath:
      "pages/Products/Individuals/AllProducts/AllProductsEnterprisePage/AllProductsEnterprisePage.js",
    name: "AllProductsEnterprisePage",
    auth: false,
    exact: false,
    layout: "MyLayout",
  },
  {
    component: "InverstoRelations",
    path: "/investor",
    componentPath: "pages/InvestorRelations/InvestorRelationsPage.js",
    name: "InvestorRelations",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MotorClaimsPage",
    path: "/:lang?/claims/motor",
    componentPath: "pages/Dashboard/DashboardClaims/Motor/MotorClaims.js",
    name: "MotorClaimsPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AboutUsLandingPage",
    path: "/:lang?/aboutus",
    componentPath: "pages/AboutUs/AboutUsLandingPage/AboutUsLandingPage.js",
    name: "AboutUsLandingPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "FinancialHighlightsPage",
    path: "/:lang?/aboutus/financialhighlights",
    componentPath: "pages/AboutUs/FinancialHighlightsPage/FinancialHighlightsPage.js",
    name: "FinancialHighlightsPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AboutUsSustainabilityPage",
    path: "/:lang?/aboutus/sustainability",
    componentPath: "pages/AboutUs/AboutUsSustainabilityPage/AboutUsSustainabilityPage.js",
    name: "AboutUsSustainabilityPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AboutUsDirectorsPage",
    path: "/:lang?/aboutus/board-of-directors",
    componentPath: "pages/AboutUs/AboutUsDirectorsPage/AboutUsDirectorsPage.js",
    name: "AboutUsDirectorsPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AboutUsDirectorsPage",
    path: "/:lang?/aboutus/senior-excutive",
    componentPath: "pages/AboutUs/AboutUsSnrExcutivePage/AboutUsSnrExcutivePage.js",
    name: "AboutUsSeniorExcutivePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AboutUsCompetitiveAdvantagePage",
    path: "/:lang?/aboutus/competitive/advantage",
    componentPath: "pages/AboutUs/AboutUsCompetitiveAdvantagePage/AboutUsCompetitiveAdvantagePage.js",
    name: "AboutUsCompetitiveAdvantagePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AboutUsFinancialNewPage",
    path: "/:lang?/aboutus/financial/new",
    componentPath: "pages/AboutUs/AboutUsFinancialNewPage/AboutUsFinancialNewPage.js",
    name: "AboutUsFinancialNewPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "CareersPage",
    path: "/:lang?/careers",
    componentPath: "pages/Careers/CareersPage.js",
    name: "CareersPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ContactUs",
    path: "/:lang?/contactus",
    componentPath: "pages/ContactUs/ContactUs.js",
    name: "ContactUs",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ContactFeedback",
    path: "/:lang?/contactus-feedback",
    componentPath: "pages/ContactUs/ContactFeedback.js",
    name: "ContactFeedback",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "AppSetting",
    path: "/:lang?/app-setting",
    componentPath: "pages/AppSetting/AppSetting.js",
    name: "AppSetting",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "CorporateLandingPage",
    path: "/:lang?/corporate",
    componentPath: "pages/HomePage/CorporateLandingPage.js",
    name: "CorporateLandingPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ClaimsPage",
    path: "/:lang?/individuals/products/motor/claims",
    componentPath: "pages/Dashboard/DashboardClaims/DashboardClaims.js",
    name: "ClaimsPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MedicalClaimsPage",
    path: "/:lang?/individuals/products/health/medical-claims",
    componentPath: "pages/Dashboard/DashboardClaims/Medical/MedicalClaims.js",
    name: "MedicalClaimsPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HomeEligibilities",
    path: "/:lang?/individuals/products/health/eligibility-letter",
    componentPath: "pages/Dashboard/EligibilityLetter/HomeEligibilities/HomeEligibilities.js",
    name: "HomeEligibilities",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "DawaiHome",
    path: "/:lang?/individuals/products/health/dawai",
    componentPath: "pages/Dashboard/Dawai/pages/Home/DawaiHome.js",
    name: "DawaiHome",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MedRemHome",
    path: "/:lang?/individuals/products/health/medical-reimbursement",
    componentPath: "pages/Dashboard/MedicalReimbursement/Home/index.js",
    name: "MedRemHome",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "HomeTeleMedicinesPage",
    path: "/:lang?/individuals/products/health/tele-medicine",
    componentPath: "pages/Dashboard/TeleMedicinePage/HomeTeleMedicinesPage/HomeTeleMedicinesPage.js",
    name: "HomeTeleMedicinesPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "MediaCenterNews",
    path: "/:lang?/mediacenter",
    componentPath: "pages/MediaCenter/MediaCenterPage.js",
    name: "MediaCenterNews",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },

  {
    component: "DomesticWorkerInsurancePage",
    path: "/:lang?/individuals/products/domestic-worker-insurance",
    componentPath: "pages/Products/Individuals/DomesticWorkerInsurancePage/DomesticWorkerInsurancePage.js",
    name: "DomesticWorkerInsurancePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "ParentInsurancePageHealth",
    path: "/:lang?/individuals/products/Parent-health-insurance",
    componentPath: "pages/Products/Individuals/ParentInsurancePageHealth/ParentInsurancePageHealth.js",
    name: "ParentInsurancePageHealth",
    layout: "MyLayout",
  },


  {
    component: "DomesticWorkerInsurancePageHealth",
    path: "/:lang?/individuals/products/domestic-worker-health-insurance",
    componentPath: "pages/Products/Individuals/DomesticWorkerInsurancePageHealth/DomesticWorkerInsurancePageHealth.js",
    name: "DomesticWorkerInsurancePageHealth",
    layout: "MyLayout",
  },

  {
    component: "MediaCenterNewsCategory",
    path: "/:lang?/mediacenter/medianewscategory",
    componentPath: "pages/MediaCenter/MediaNewsCategoryPage.js",
    name: "MediaCenterNewsCategory",
    layout: "MyLayout",
  },
  {
    component: "MediaCenterNewsDetails",
    path: "/:lang?/mediacenter/medianewsdetails",
    componentPath: "pages/MediaCenter/MediaCenterNewsDetailsPage.js",
    name: "MediaCenterNewsDetails",
    layout: "MyLayout",
  },
  {
    component: "InsuranceAgentPage",
    path: "/:lang?/individuals/customer-service/insurance-agent",
    componentPath: "pages/InsuranceAgent/InsuranceAgent",
    name: "InsuranceAgentPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "SocialPage",
    path: "/:lang?/social",
    componentPath: "pages/SocialPage/SocialPage.js",
    name: "SocialPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "SocialProject",
    path: "/:lang?/social/project",
    componentPath: "pages/SocialPage/SocialProject.js",
    name: "SocialProject",
    layout: "MyLayout",
  },
  {
    component: "HealthInsurancePage",
    path: "/:lang?/individuals/products/health/health-insurance",
    componentPath: "pages/HealthInsurancePage/HealthInsurancePage.js",
    name: "HealthInsurancePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "EmergencySupport",
    path: "/:lang?/individuals/emergency-support",
    componentPath: "pages/EmergencySupport/EmergencySupport.js",
    name: "EmergencySupport",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "FlightDelayAssistance",
    path: "/:lang?/individuals/flight-delay-assistance",
    componentPath: "pages/EmergencySupport/FlightDelayAssistance/FlightDelayAssistance.js",
    name: "FlightDelayAssistance",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "CarAccident",
    path: "/:lang?/individuals/car-accident",
    componentPath: "pages/EmergencySupport/CarAccident/CarAccident.js",
    name: "CarAccident",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  { 
    component: "AllProductsMobilePage",
    path: "/:lang?/individuals/all-products-mobile",
    componentPath: "pages/AllProducts/AllProductsMobile/AllProductsMobilePage.js",
    name: "AllProductsMobilePage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "PolicyTerms",
    path: "/:lang?/privacy-policy",
    componentPath: "pages/PolicyTerms/PrivacyPolicyPage.js",
    name: "PrivacyPolicyPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "TawuniyaPetrominPage",
    path: "/:lang?/individuals/loyalty-program/tawuniya-petromin",
    componentPath: "pages/LoyaltyProgramsPage/TawuniyaPetrominPage",
    name: "TawuniyaPetrominPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "TawuniyaPetrominPageOffer",
    path: "/:lang?/individuals/loyalty-program/tawuniya-petromin/offers",
    componentPath: "pages/LoyaltyProgramsPage/TawuniyaPetrominPageOffer",
    name: "TawuniyaPetrominPageOffer",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "TawuniyaIthraPage",
    path: "/:lang?/individuals/loyalty-program/tawuniya-ithra",
    componentPath: "pages/LoyaltyProgramsPage/TawuniyaIthraPage",
    name: "TawuniyaIthraPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "TawuniyaVitalityPage",
    path: "/:lang?/individuals/loyalty-program/tawuniya-vitality",
    componentPath: "pages/LoyaltyProgramsPage/TawuniyaVitalityPage",
    name: "TawuniyaVitalityPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "LoginPage",
    path: "/:lang?/login",
    componentPath: "pages/Auth/LoginPage/LoginPage.js",
    name: "LoginPage",
    auth: false,
    exact: true,
    layout: "LoginLayout",
  },
  {
    component: "LoginVerifyPage",
    path: "/:lang?/login/verify",
    componentPath: "pages/Auth/LoginPage/LoginVerifyPage.js",
    name: "LoginVerifyPage",
    auth: false,
    exact: true,
    layout: "LoginLayout",
  },
  {
    component: "RegisterPage",
    path: "/:lang?/register",
    componentPath: "pages/Auth/RegisterPage/RegisterPage.js",
    name: "RegisterPage",
    auth: false,
    exact: true,
    layout: "LoginLayout",
  },
  {
    component: "RegisterVerifyPage",
    path: "/:lang?/register/verify",
    componentPath: "pages/Auth/RegisterPage/RegisterVerifyPage.js",
    name: "RegisterVerifyPage",
    auth: false,
    exact: true,
    layout: "LoginLayout",
  },
  {
    component: "AbsherAuthPage",
    path: "/:lang?/register/absher-authentication",
    componentPath: "pages/Auth/RegisterPage/AbsherAuthPage.js",
    name: "AbsherAuthPage",
    auth: false,
    exact: true,
    layout: "LoginLayout",
  },
  {
    component: "DashboardHome",
    path: "/:lang?/dashboard",
    componentPath: "pages/Dashboard/DashboardHome/DashboardHome.js",
    name: "DashboardHome",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "CoverageAndBenefits",
    path: "/:lang?/dashboard/coverageAndBenefits",
    componentPath: "pages/CoverageAndBenefits/index.js",
    name: "CoverageAndBenefits",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "MyProfilePage",
    path: "/:lang?/dashboard/myprofile",
    componentPath: "pages/MyProfilePage/MyProfilePage.js",
    name: "MyProfilePage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "MyProfilePage",
    path: "/:lang?/dashboard/myprofile/update",
    componentPath: "pages/MyProfilePage/MyProfileUpdatePage.js",
    name: "MyProfileUpdatePage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DashboardPolicyDetail",
    path: "/:lang?/dashboard/policy-detail",
    componentPath: "pages/Dashboard/DashBoardPolicyDetails/DashBoardPolicyDetails.js",
    name: "DashboardPolicyDetail",
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DashboardAllService",
    path: "/:lang?/dashboard/service",
    componentPath: "pages/Dashboard/DashboardHome/DashboardAllService/DashboardAllService.js",
    name: "DashboardAllService",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DashboardNotificationsPage",
    path: "/:lang?/dashboard/notifications",
    componentPath: "pages/Dashboard/DashboardNotificationsPage/DashboardNotificationsPage.js",
    name: "DashboardNotificationsPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DashboardPolicies",
    path: "/:lang?/dashboard/your-policies",
    componentPath: "pages/Dashboard/DashboardPolicies/DashboardPolicies.js",
    name: "DashboardPolicies",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "ServicesPage",
    path: "/:lang?/dashboard/service/:url",
    componentPath: "pages/Dashboard/ServicesPage/ServicesPage.js",
    name: "ServicesPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DashboardProductsAndOfferings",
    path: "/:lang?/dashboard/products-and-offerings",
    componentPath: "pages/Dashboard/DashboardProductsAndOfferings/DashboardProductsAndOfferings.js",
    name: "DashboardProductsAndOfferings",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DashboardSupportCenterPage",
    path: "/:lang?/dashboard/support-center",
    componentPath: "pages/Dashboard/DashboardSupportCenter/DashboardSupportCenter.js",
    name: "DashboardSupportCenterPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "AllClaimsPage",
    path: "/:lang?/dashboard/all-claims",
    componentPath: "pages/Dashboard/DashboardClaims/AllClaims/AllClaims.js",
    name: "AllClaimsPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DashboardRequestsAndApprovalsPage",
    path: "/:lang?/dashboard/requests-and-approvals",
    componentPath: "pages/Dashboard/DashboardRequestsAndApprovals/DashboardRequestsAndApprovals.js",
    name: "DashboardRequestsAndApprovalsPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RoadAssistantLandingPage",
    path: "/:lang?/dashboard/service/road-assistance",
    componentPath:
      "pages/Dashboard/RoadAssistancePage/RoadAssistantLandingPage/RoadAssistantLandingPage.js",
    name: "RoadAssistantLandingPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RoadAssistanceNewRequest",
    path: "/:lang?/dashboard/service/road-assistance/new-request",
    componentPath:
      "pages/Dashboard/RoadAssistancePage/RoadAssistantNewRequest/RoadAssistanceNewRequest.js",
    name: "RoadAssistanceNewRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RoadAssistanceSummary",
    path: "/:lang?/dashboard/service/road-assistance/summary",
    componentPath:
      "pages/Dashboard/RoadAssistancePage/RoadAssistanceSummary/RoadAssistanceSummary.js",
    name: "RoadAssistanceSummary",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RoadAssistantTrackId",
    path: "/:lang?/dashboard/service/road-assistance/request-confirmation/:id",
    componentPath: "pages/Dashboard/RoadAssistancePage/RoadAssistantTrackId/RoadAssistantTrackId.js",
    name: "RoadAssistantTrackId",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomePeriodicalInspection",
    path: "/:lang?/dashboard/service/periodic-inspection",
    componentPath:
      "pages/Dashboard/HomePeriodicInspectionPage/HomePeriodicalInspection/HomePeriodicalInspection.js",
    name: "HomePeriodicalInspection",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "PeriodicInspectionNewRequest",
    path: "/:lang?/dashboard/service/periodic-inspection/new-request",
    componentPath:
      "pages/Dashboard/HomePeriodicInspectionPage/PeriodicInspectionNewRequest/PeriodicInspectionNewRequest.js",
    name: "PeriodicInspectionNewRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "PeriodicInspectTrackId",
    path: "/:lang?/dashboard/service/periodic-inspection/request-confirmation/:id",
    componentPath:
      "pages/Dashboard/HomePeriodicInspectionPage/PeriodicInspectTrackId/PeriodicInspectTrackId.js",
    name: "PeriodicInspectTrackId",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeCarWasherPage",
    path: "/:lang?/dashboard/service/career-wash",
    componentPath: "pages/Dashboard/CarWashPage/HomeCarWasherPage/HomeCarWasherPage.js",
    name: "HomeCarWasherPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "CarWasherRequestPage",
    path: "/:lang?/dashboard/service/car-wash/new-request",
    componentPath: "pages/Dashboard/CarWashPage/CarWasherRequestPage/CarWasherRequestPage.js",
    name: "CarWasherRequestPage",
    auth: false,
    exact: true,
  },
  {
    component: "CarWasherRequestPage",
    path: "/:lang?/dashboard/service/car-wash/new-request",
    componentPath: "pages/Dashboard/CarWashPage/CarWasherRequestPage/CarWasherRequestPage.js",
    name: "CarWasherRequestPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "CarWasherRequesId",
    path: "/:lang?/dashboard/service/car-wash/request-confirmation/:id",
    componentPath: "pages/Dashboard/CarWashPage/CarWasherRequestId/CarWasherRequestId.js",
    name: "CarWasherRequesId",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeCarMaintainancePage",
    path: "/:lang?/dashboard/service/car-maintainance",
    componentPath:
      "pages/Dashboard/CarMaintainPage/HomeCarMaintainancePage/HomeCarMaintainancePage.js",
    name: "HomeCarMaintainancePage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "CarMaintainanceNewRequest",
    path: "/:lang?/dashboard/service/car-maintainance/new-request",
    componentPath:
      "pages/Dashboard/CarMaintainPage/CarMaintainanceNewRequest/CarMaintainanceNewRequest.js",
    name: "CarMaintainanceNewRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "CarMaintainanceTrackId",
    path: "/:lang?/dashboard/service/car-maintainance/request-confirmation/:id",
    componentPath: "pages/Dashboard/CarMaintainPage/CarMaintainanceTrackId/CarMaintainanceTrackId.js",
    name: "CarMaintainanceTrackId",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeChildVaccination",
    path: "/:lang?/dashboard/child-vaccination",
    componentPath: "pages/Dashboard/ChildVaccinatePage/HomeChildVaccination/HomeChildVaccination.js",
    name: "HomeChildVaccination",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeEligibilities",
    path: "/:lang?/dashboard/service/eligibility-letter",
    componentPath: "pages/Dashboard/EligibilityLetter/HomeEligibilities/HomeEligibilities.js",
    name: "HomeEligibilities",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "EligibilityNewRequest",
    path: "/:lang?/dashboard/service/eligibility-letter/new-request",
    componentPath: "pages/Dashboard/EligibilityLetter/EligibilityNewRequest/EligibilityNewRequest.js",
    name: "EligibilityNewRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "EligibilityLetterTrackId",
    path: "/:lang?/dashboard/service/eligibility-letter/request-confirmation/:id",
    componentPath:
      "pages/Dashboard/EligibilityLetter/EligibilityLetterTrackId/EligibilityLetterTrackId.js",
    name: "EligibilityLetterTrackId",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeTeleMedicinesPage",
    path: "/:lang?/dashboard/service/request-telemedicine",
    componentPath: "pages/Dashboard/TeleMedicinePage/HomeTeleMedicinesPage/HomeTeleMedicinesPage.js",
    name: "HomeTeleMedicinesPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RequestDetailsPage",
    path: "/:lang?/dashboard/service/tele-medicine/new-request",
    componentPath: "pages/Dashboard/TeleMedicinePage/RequestDetailsPage/RequestDetailsPage.js",
    name: "RequestDetailsPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "TeleRequestIdPage",
    path: "/:lang?/dashboard/service/tele-medicine/request-confirmation/:id",
    componentPath: "pages/Dashboard/TeleMedicinePage/RequestIdsPage/RequestIdsPage.js",
    name: "TeleRequestIdPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomePregnancyProgramPage",
    path: "/:lang?/dashboard/service/pregnancy-follow-up-program",
    componentPath: "pages/Dashboard/PregnancyProgram/HomePregnancyPage/HomePregnancyPage.js",
    name: "HomePregnancyProgramPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "PregnancyRequestDetailsPage",
    path: "/:lang?/dashboard/service/pregnancy-follow-up-program/new-request",
    componentPath: "pages/Dashboard/PregnancyProgram/RequestDetailsPage/RequestDetailsPage.js",
    name: "PregnancyRequestDetailsPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "PregnancyRequestIdPage",
    path: "/:lang?/dashboard/service/pregnancy-follow-up-program/request-confirmation",
    componentPath: "pages/Dashboard/PregnancyProgram/RequestIdsPage/RequestIdsPage.js",
    name: "PregnancyRequestIdPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeChildPage",
    path: "/:lang?/dashboard/service/home-child-vaccination",
    componentPath:
      "pages/Dashboard/HomeChildVaccination/HomeChildVaccinationPage/HomeChildVaccinationPage.js",
    name: "HomeChildPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeChildVaccinationRequest",
    path: "/:lang?/dashboard/service/home-child-vaccination/new-request",
    componentPath: "pages/Dashboard/HomeChildVaccination/RequestDetailsPage/RequestDetailsPage.js",
    name: "HomeChildVaccinationRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "HomeChildVaccinationResponse",
    path: "/:lang?/dashboard/service/home-child-vaccination/request-confirmation",
    componentPath: "pages/Dashboard/HomeChildVaccination/RequestIdsPage/RequestIdsPage.js",
    name: "HomeChildVaccinationResponse",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "ChronicDiseaseManagementPage",
    path: "/:lang?/dashboard/service/chronic-disease-management",
    componentPath:
      "pages/Dashboard/ChronicDiseaseManagement/ChronicDiseaseManagement/ChronicDiseaseManagement.js",
    name: "ChronicDiseaseManagementPage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "ChronicDiseaseManagementRequest",
    path: "/:lang?/dashboard/service/chronic-disease-management/new-request",
    componentPath:
      "pages/Dashboard/ChronicDiseaseManagement/RequestDetailsPage/RequestDetailsPage.js",
    name: "ChronicDiseaseManagementRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "ChronicDiseaseManagementResponse",
    path: "/:lang?/dashboard/service/chronic-disease-management/request-confirmation",
    componentPath: "pages/Dashboard/ChronicDiseaseManagement/RequestIdsPage/RequestIdsPage.js",
    name: "ChronicDiseaseManagementResponse",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "AssistAmerica",
    path: "/:lang?/dashboard/service/assist-america",
    componentPath: "pages/Dashboard/AssistAmerica/AssistAmerica/AssistAmerica.js",
    name: "AssistAmerica",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RoadAssistanceMotorServicePage",
    path: "/:lang?/dashboard/motor-service",
    componentPath: "pages/RoadAssistanceMotorServicePage/RoadAssistanceMotorServicePage.js",
    name: "RoadAssistanceMotorServicePage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RoadAssistRequestMobile",
    path: "/:lang?/dashboard/request",
    componentPath: "pages/RoadAssistRequestMobile/index.js",
    name: "RoadAssistRequestMobile",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DawaiHome",
    path: "/:lang?/dashboard/service/dawai",
    componentPath: "pages/Dashboard/Dawai/pages/Home/DawaiHome.js",
    name: "DawaiHome",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "RequestDetails",
    path: "/:lang?/dashboard/service/dawai-request",
    componentPath: "pages/Dashboard/Dawai/pages/RequestDetails/RequestDetails.js",
    name: "RequestDetails",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "Summary",
    path: "/:lang?/dashboard/service/dawai-summary",
    componentPath: "pages/Dashboard/Dawai/pages/Summary/Summary.js",
    name: "Summary",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "EmabassyLetterHome",
    path: "/:lang?/dashboard/service/embassy-letter",
    componentPath: "pages/Dashboard/EmbassyLetter/Home/EmbassyHome.js",
    name: "EmabassyLetterHome",
    auth: true,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "EmabassyLetterNewRequest",
    path: "/:lang?/dashboard/service/embassy-letter/new-request",
    componentPath: "pages/Dashboard/EmbassyLetter/NewRequest/NewRequest.js",
    name: "EmabassyLetterNewRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "EmabassyLetterStatus",
    path: "/:lang?/dashboard/service/embassy-letter/new-request/status",
    componentPath: "pages/Dashboard/EmbassyLetter/Status/Status.js",
    name: "EmabassyLetterStatus",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "MedRemHome",
    path: "/:lang?/dashboard/service/medical-reimbursement",
    componentPath: "pages/Dashboard/MedicalReimbursement/Home/index.js",
    name: "MedRemHome",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "MedRemNewRequest",
    path: "/:lang?/dashboard/service/medical-reimbursement/new-request",
    componentPath: "pages/Dashboard/MedicalReimbursement/NewRequest/index.js",
    name: "MedRemNewRequest",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "MedRemRequestStatus",
    path: "/:lang?/dashboard/service/medical-reimbursement/request-status",
    componentPath: "pages/Dashboard/MedicalReimbursement/RequestStatus/index.js",
    name: "MedRemRequestStatus",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DesktopClaims",
    path: "/:lang?/dashboard/individuals/products/motor/claims",
    componentPath: "pages/DesktopClaims/DesktopClaims.js",
    name: "DesktopClaims",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "DesktopApprovals",
    path: "/:lang?/dashboard/approvals",
    componentPath: "pages/RequestsAndApprovals/index.js",
    name: "DesktopApprovals",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "NetworkCoverage",
    path: "/:lang?/dashboard/individuals/customer-service/network-coverage",
    componentPath: "pages/Dashboard/DashboardHome/NetworkCoverage/NetworkCoverage.js",
    name: "NetworkCoverage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "ProfilePage",
    path: "/:lang?/dashboard/profile",
    componentPath: "pages/Profile/Profile.js",
    name: "ProfilePage",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "MedApprovalDetails",
    path: "/:lang?/dashboard/approval-details",
    componentPath: "pages/Approvals/MedApprovalDetailsPage.js",
    name: "MedApprovalDetails",
    auth: false,
    exact: true,
    layout: "RoadAssisstanceLayout",
  },
  {
    component: "Health",
    path: "/:lang?/welcome-kit/health",
    componentPath: "pages/WelcomeKits/Health/Health.js",
    name: "Health",
    auth: false,
    exact: true,
    layout: "WelcomeKitLayout",
  },
  {
    component: "TawuniyaAuction",
    path: "/:lang?/tawuniy-auction",
    componentPath: "pages/PolicyTerms/TawuniyaAuctionPage.js",
    name: "TawuniyaAuctionPage",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "DomesticWorkerContractInsurance",
    path: "/:lang?/domestic-worker",
    componentPath: "pages/DomesticWorker/DomesticContractInsurance.js",
    name: "DomesticContractInsurance",
    auth: false,
    exact: true,
    layout: "WelcomeKitLayout",
  },
  {
    component: "GreetingContainer",
    path: "/:lang?/greeting",
    componentPath: "pages/Greeting/GreetingContainer.js",
    name: "Greeting",
    auth: false,
    exact: true,
    layout: "WelcomeKitLayout",
  },
  {
    component: "GreetingContainer",
    path: "/:lang?/greeting-meena",
    componentPath: "pages/GreetingMeena/GreetingContainer.js",
    name: "Greeting",
    auth: false,
    exact: true,
    layout: "WelcomeKitLayout",
  },
  
  // {
  //   component: "EidGreetingGenerator",
  //   path: "/eid-greeting",
  //   componentPath: "pages/EidGreeting/EidGreetingContainer.js",
  //   name: "EidGreeting",
  //   auth: false,
  //   exact: true,
  //   layout: "WelcomeKitLayout",
  // },
  {
    component: "EJARO",
    path: "/:lang?/ejaro",
    componentPath: "pages/Ejaro",
    name: "EJARO",
    auth: false,
    exact: true,
    layout: "MyLayout",
  },
  {
    component: "LIFEINSURANCE",
    path: "/:lang?/individuals/products/life-insurance",
    componentPath: "pages/LifeInsurance",
    name: "LIFEINSURANCE",
    auth: false,
    exact: true,
    layout: "MyLayout",
  }
];
