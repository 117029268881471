import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MenuPopup from "./MenuPopup";
import { NormalButton } from "../NormalButton";
import { NormalSearch } from "../NormalSearch";
import logo from "assets/svg/Tawuniya-Logo.svg";
import clockIcon from "assets/svg/HeaderArrow.svg";
import phoneIcon from "assets/svg/phoneCircleIcon.svg";
import mailIcon from "assets/svg/mail icon.svg";
import chatIcon from "assets/svg/chat icon.svg";
import searchIcon from "assets/svg/headerSearchLight.svg";
import WorldIcon from "assets/svg/world-icon.svg";
import "./navbar.scss";
import { useTranslation } from "react-i18next";
import { DetectOutsideClicks } from "../../../hooks";
import { staticSearchData } from "component/common/MockData";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

export const Navbar = ({
  handleToggler,
  toggleLanguageCard,
  toggleSupportCard,
  toggleEmergencyCard,
  toggleGetStartedCard,
  navContent,
}) => {
  const { language } = useSelector((data) => data.languageReducer);
  const [search, setSearch] = useState("");
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [swap, setSwap] = useState(null);
  const [transition, setTransition] = useState(0);
  const [langSpecificResults, setLangSpecificResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const changeImg = [phoneIcon, mailIcon, chatIcon];


  useEffect(() => {
    let lang = localStorage.getItem("newTawuniyaLanguageParam");
    if (!lang) {
      localStorage.setItem("newTawuniyaLanguageParam", "ar")
    }
  }, []);

  useEffect(() => {
    setSwap(changeImg[transition]);
  }, [transition]);

  useEffect(() => {
    const pathName = history.location.pathname;
    const tab = queryParams.get('tab');
    if (pathName === "/individuals") {
      setMenuOpen(0);
    } else if (pathName === "/corporate") {
      setMenuOpen(1);
    } else if (pathName === "/investor") {
      setMenuOpen(2);
    } if (pathName === "/individuals" && tab === "claims") {
      setMenuOpen(3);
      window.scrollTo(0, 0);
    }

  }, [history.location.pathname]);

  useEffect(() => {
    const tab = queryParams.get('tab');
    if (tab === "claims") {
      setMenuOpen(3);
      window.scrollTo(0, 0);
    }
  }, [location.search]);

  const handleItemClick = (index, link, id) => {
    if (link?.includes('https')) {
      window.open(link, '_blank');
    } else {
      const isSameItem = index === menuOpen;
      setMenuOpen(isSameItem ? null : index);
      history.push(link);
    }
  };
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (changeImg.length === transition + 1) {
        setTransition(0);
      } else {
        setTransition(transition + 1);
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [transition, changeImg]);

  useEffect(() => {
    if (language) {
      const lSpecData = staticSearchData.filter((item) => item.lang === "EN" || item.lang === "AR");
      setLangSpecificResults(lSpecData);
    }
  }, [language]);

  useEffect(() => {
    if (!search) return setSearchResults([]);

    const fSearch = langSpecificResults.filter((item) =>
      item.keywords.split(",").find((itm) => itm?.toLowerCase().includes(search && search.toLowerCase()))
    );
    setSearchResults(fSearch);
  }, [search]);

  const authNavLinks = [
    {
      id: 0,
      link: "/login",
    },
    {
      id: 1,
      link: "/login/verify",
    },
    {
      id: 2,
      link: "/register",
    },
    {
      id: 3,
      link: "/register/verify",
    },
    {
      id: 4,
      link: "/register/absher-authentication",
    },
  ];

  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const searchArea = useRef(null);
  const isClickOutside = DetectOutsideClicks(searchArea);

  useEffect(() => {
    if (isSearchOpened) {
      isClickOutside && setIsSearchOpened(false);
    }
  }, [isClickOutside]);

  const handleActionClick = (url) => {
    setIsSearchOpened(false);
    setSearch("");
    history.push(url.replace('https://www.tawuniya.com', ''));
  };

  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  }

  return (
    <div className="navbar" id="navbarDesktop">
      <div className="d-flex justify-content-between align-items-center w-100 h-100 pt-3">
        <div className="mb-2 img-container">
          <Link to={'/'}>
            <img
              src={logo}
              className={`tawuniyaLogo cursor-pointer ${isRTL ? "tw-ar" : "tw-en"}`}
              alt="tawuniyaLogo" />
          </Link>
          {/* <button className={`clockover-text ${isRTL ? "clockover-text-ar" : "clockover-text-en"}`}>
      <a
       href="https://www.tawuniya.com.sa/en"
       target="_blank"
       className="global_link global_link_flex">
       <img src={clockIcon} className={`clock-icon ${isRTL ? "rotate-180" : ""}`} alt="clockIcon" />
       <span className="clock-text">{t("navbar.goToWebsite")}</span>
      </a>
     </button>*/}
        </div>
        {navContent.map((item, index) => {
          return (
            <div className="pl-3 mb-2" key={item.navText + index} onClick={() => { handleItemClick(item.id, item.link); 
            window.lmSMTObj.track(item.navText + 'TabClicked') 
            }}>
              {item.link ? <div className="navLink-Text  test fs-16 fw-400">
                <p>{item.navText}</p>
              </div> :
                <p className="navLink-Text  test fs-16 fw-400">
                  {item.navText}
                </p>}
            </div>
          )
        })}
        <div className="d-flex justify-content-end w-100 align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            <div className="px-2">
              <div className="currently-selected-language" onClick={toggleLanguageCard}>
                {language !== "ar" ? "عربي" : ""}
                <img src={WorldIcon} className="img-fluid languageBtnIcon" alt="WorldIcon" />
                {language === "ar" ? "En" : ""}
              </div>
            </div>
            <div className="pr-lg-1">
              {isSearchOpened ? (
                <React.Fragment>
                  <NormalSearch
                    className="headerSearch"
                    name="search"
                    value={search}
                    placeholder={t("navbar.searchPlaceholder")}
                    onChange={(e) => { setSearch(e.target.value); }}
                    needRightIcon={true}
                  />
                  {search !== "" && (
                    <div className="searchResultBox">
                      <p className="fs-12 fw-800 search-ResultTitle">{t("navbar.results")}</p>
                      {searchResults.length === 0 ? (
                        <p className="fs-10 fw-400 search-ResultPara">{t("navbar.noResultFound")}</p>
                      ) : (
                        <React.Fragment>
                          <p className="fs-10 fw-400 search-ResultPara">
                            {t("navbar.search.found")} {searchResults.length} {t("navbar.search.res_for")}{" "}
                            <span>“</span>
                            <span className="results_color">{search}</span>
                            <span>”</span>
                          </p>
                          {searchResults.map((item, idx) => {
                            return (
                              <div className="d-flex justify-content-between resultContainer" key={idx}>
                                <div className="py-2">
                                  <p className="fs-10 fw-400 result_Name m-0">{item.category}</p>
                                </div>
                                <div
                                  className="py-2 resultContainer_action"
                                  onClick={() => { handleActionClick(item.webUrl); trackEvent(item.action + " for " + item.category + " Clicked") }}
                                >
                                  <p className="fs-10 fw-400 result_Title m-0 cursor-pointer" style={{ color: "#6b47f5" }}>{item.action}</p>

                                </div>
                              </div>
                            );
                          })}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <img
                  src={searchIcon}
                  className="img-fluid searchBtnIcon"
                  alt="searchicon"
                  onClick={() => setIsSearchOpened(!isSearchOpened)}
                />
              )}
            </div>

            <div className="pr-lg-2">
              <img
                src={swap}
                className="img-fluid notifyBtnIcon"
                onClick={toggleSupportCard}
                alt="notifyicon"
              />
            </div>
            <React.Fragment>
              <div className="pr-lg-3">
                <NormalButton
                  label={t("navbar.emergency")}
                  className="getStartBtn"
                  onClick={() => { toggleEmergencyCard(); trackEvent(t("navbar.emergency") + "TabClicked") }}
                />
              </div>
              <div>
                <NormalButton
                  label={t("navbar.myServices")}
                  className="emergencyBtn"
                  onClick={() => { toggleGetStartedCard(); trackEvent(t("navbar.myServices") + "TabClicked") }}
                />
              </div>
            </React.Fragment>
            {/* {authNavLinks.map(
       (item, index) =>
        history.location.pathname === item.link && (
         <div key={item.link + index} className="d-flex">
          <div className="pl-lg-3">
           <NormalButton
            label={t("navbar.emergency")}
            className="redEmerencyBtn"
            onClick={toggleEmergencyCard}
           />
          </div>
          <div className="pl-lg-3">
           <NormalButton
            label={t("navbar.myServices")}
            className="emergencyBtn"
            onClick={toggleGetStartedCard}
           />
          </div>
         </div>
        )
      )} */}
          </div>
        </div>
      </div>
      {menuOpen !== null && (
        <MenuPopup navContent={navContent} menuIndex={menuOpen} setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
      )}
    </div>
  );
};
