import React, { useEffect, useState } from "react";
import { NormalButton } from "../../NormalButton";
import { NormalSelect } from "../../NormalSelect";
import cam from "assets/images/mobile/cam.png";
import selectArrow from "assets/svg/selectArrowIconHome.svg";
import "../style.scss";
import { useTranslation } from "react-i18next";
import CommonIndividualsForm from "./CommonIndividualsForm/CommonIndividualsForm";
import { useSelector } from "react-redux";

export const MotorForm = ({
  title = "Individuals",
  inside,
  isBtn = false,
  isRtl = false,
  isRedirect = false,
  isVisitVisa = false,
  isMedmal = false,
}) => {
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const [btnPill, setBtnPill] = useState(false);
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const personalLink =
    selectedLanguage === "en"
      ? `https://store.tawuniya.com.sa/en/web/store/main-motor?token=`
      : `https://store.tawuniya.com.sa/ar/web/store/main-motor?token=`;
  const smeLink = "https://smemotor.tawuniya.com.sa/";

  useEffect(() => {
    if (isVisitVisa) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          funnel_step: "step_0",
          step_name: "visit_visa_extension_view_item",
          page_source: "internal_click",
          items: [
            {
              item_name: "Visit Visa Extension",
              item_category: "Medical Insurance",
              item_class: "Individual",
            },
          ],
        },
      });
    }
  }, []);

  return (
    <div className="recentFeed-SubForm mt-3">
      <div className="row">
        <div className="col-12">
          {!isBtn && (
            <div className="row m-0">
              <div className="col-12 p-0">
                <NormalButton
                  label={t(title)}
                  className={
                    !btnPill
                      ? "inndividualDashboardBtn-highlight"
                      : "inndividualDashboardBtn"
                  }
                  onClick={() => setBtnPill(false)}
                />
              </div>
              {/* <div className="col-6 p-0 pl-1">
                <NormalButton
                  label={t("SMEs")}
                  className={
                    btnPill ? "smeDashboardBtn-highlight" : "smeDashboardBtn"
                  }
                  onClick={() => setBtnPill(true)}
                />
              </div> */}
            </div>
          )}
        </div>
        <div className="col-12">
          <CommonIndividualsForm
            sme={btnPill}
            application={!isRedirect ? "MOTOR" : ""}
            url={!isRedirect ? (btnPill ? smeLink : personalLink) : ""}
            isRedirect={isRedirect}
            isVisitVisa={isVisitVisa}
            isMedmal={isMedmal}
          />
          {!inside && (
            <p className="fs-9 fw-400 recentFeedTerms pt-2 m-0">
              {t("form_agreement")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
