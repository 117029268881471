import React from "react";
import { NormalButton } from "component/common/NormalButton";
import orangeArrow from "assets/svg/orangeArrow.svg";
import arrowLight from "assets/svg/Arrows/ArrowForward.svg";
import fileclaim from "assets/svg/files.svg";
import "./style.scss";
import { history } from "service/helpers";
import flight from "assets/svg/orangeFlight.svg";
import roadCar from "assets/svg/roadCar.svg";
import medikit from "assets/svg/medicalkit.svg";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import phoneSvg from "assets/svg/myservices_popup_phone.svg";
import appStore from "assets/images/appleStoreFooter.png";
import playStore from "assets/images/playStoreFooter.png";

const appData = [appStore, playStore];

export const GetStartedCard = () => {
  const openInNewTab = url1 => {
    const newWindow = window.open(url1, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const userID = useSelector(
    state => state?.loginDetailsReducer?.loginResponse?.user_info?.userID
  );
  const { i18n, t } = useTranslation();
  const serviceStartedData = [
    {
      id: 0,
      icon: roadCar,
      serviceTitle: t("road_side_assistance"),
      url: isEmpty(userID)
        ? "/individuals/customer-service/road-assistance"
        : "/dashboard/service/road-assistance",
      urlTrue: false
    },
    {
      id: 1,
      icon: medikit,
      serviceTitle: t("PolicyDetail.RequestTele"),
      url: isEmpty(userID)
        ? "/individuals/products/health/tele-medicine"
        : "/dashboard/service/request-telemedicine",
      urlTrue: false
    }
    // {
    //     id: 2,
    //     icon: flight,
    //     serviceTitle: t("Flight_Delay"),
    //     url: "/individuals/flight-delay-assistance",
    //     urlTrue: false,
    // },
    // {
    //  id: 3,
    //  icon: fileclaim,
    //  serviceTitle: t("Submit_and_Track"),
    //  urlTrue: true,
    // // url: "https://www.tawuniya.com.sa/individual/claims",
    // },
  ];

  const trackEvent = tabClicked => {
    window.lmSMTObj.track(tabClicked);
  };

  const handleSmeButtonClick = () => {
    window.open("https://tawuniya.com/sme-eservice/auth/login", "_blank");
  };

  const handleCorporateButtonClick = () => {
    window.open(
      "https://online.tawuniya.com.sa/Tawuniya/portal/home.action?langId=1&_gl=1*",
      "_blank"
    );
  };

  return (
    <div className="getStartedContainer card-GetStarted-Layout">
      <div className="row">
        <div className="getStartedLayerTwo col-lg-5 col-12 px-4 pt-4">
          <div className="d-flex flex-column gap-5">
            <div>
              <h5 className="my-services-popup-left-title">
              {t("my-services.individual")}
              </h5>
              <p>
              {t("my-services.individual-content")}
              </p>

              <div className="footerPositionIcons mb-3">
                <div
                  className="d-flex flex-lg-row flex-md-row flex-column pb-lg-0 pb-3 classchanges"
                  style={{ cursor: "pointer" }}
                >
                  {appData.map((i, index) => {
                    return (
                      <a
                        title={index === 0 ? "APP Store" : "Google Play"}
                        href={
                          index === 0
                            ? "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191"
                            : "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance"
                        }
                        target={"_blank"}
                        onClick={() =>
                          trackEvent(
                            index === 0
                              ? "APP Store"
                              : "Google Play" + "TabClicked"
                          )
                        }
                        key={index}
                      >
                        <img
                          src={i}
                          className="img-fluid pr-lg-3 pr-md-3"
                          alt="socialapp"
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="flex-fill text-center">
              <img src={phoneSvg} alt="mobilephone" width="260" />
            </div>
          </div>
          {/* <div className="emergencyLining pb-3">
                        <div className="heading pt-3 fs-22">{t("myServices.iamIndividual")}</div>
                    </div>
                    <div className="pt-3 pb-4">
                        <Link to="/login" className="global_link">
                            <NormalButton
                                label={t("My_Account")}
                                className="myAccountBtn p-4"
                                needBtnPic={true}
                                adjustIcon="pl-2"
                                src={arrowLight}
                            onClick={() => trackEvent(t("My_Account")+"TabClicked")}
                            />
                        </Link>
                    </div>
                    {serviceStartedData.map((item, index) => {
                        let { icon, serviceTitle } = item;
                        return (
                            <div
                                key={index}
                                className="d-flex justify-content-start align-items-center mb-1"
                                style={{
                                    gap: ".5rem",
                                }}
                            >
                                <img src={icon} className="img-fluid serviceIconSize" alt="icon" />
                                {item.urlTrue ? (
                                    <a href={item.url} target="_blank" onClick={() => trackEvent(item.serviceTitle+"TabClicked")}>
                                        <p className="fs-16 m-0  getStartedPara">{serviceTitle}</p>zah
                                    </a>
                                ) : (
                                    <Link to={item.url} className="global_link" onClick={() => trackEvent(item.serviceTitle+"TabClicked")}>
                                        <p className="fs-16 m-0  getStartedPara">{serviceTitle}</p>
                                    </Link>
                                )}
                            </div>
                        );
                    })} */}
        </div>

        <div className="getStartedLayerOne col-lg-7 col-12 p-4">
          <div className="my-services-right-container">
            <div className="d-flex flex-column flex-fill banner-parent">
              <div className="my-services-banner-container-sme">
                <p className="my-services-banner-text">
                {t("my-services.sme")}
                </p>
              </div>

              <div className="banner-details">
              <h6>{t("my-services.corporate-services")}</h6>
                  <p>
                    {t("my-services.sme-content")}{" "}
                    <strong>{t("my-services.sme-portal")}</strong>
                  </p>
                <button
                  onClick={handleSmeButtonClick}
                  class="btn cursor-pointer d-flex justify-content-center align-items-center my-services-login-btn p-3"
                >
                 <span>{t("my-services.sme-login")} &nbsp;&nbsp; &gt;</span>
                </button>
              </div>
            </div>

            <div className="d-flex flex-column  flex-fill banner-parent">
              <div className="my-services-banner-container-corporate">
              <p className="my-services-banner-text">
                    {t("my-services.corporate")}
                  </p>
              </div>
              <div className="banner-details">
                  <h6>{t("my-services.corporate-services")}</h6>
                  <p>
                    {t("my-services.corporate-content")}{" "}
                    <strong>{t("my-services.corporate-portal")}</strong>
                  </p>
                  <button
                    onClick={handleCorporateButtonClick}
                    class="btn cursor-pointer d-flex justify-content-center align-items-center my-services-login-btn p-3"
                  >
                    <span>{t("my-services.corporate-login")} &nbsp;&nbsp; &gt;</span>
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
